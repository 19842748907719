import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { createUser } from "../../utils/user";
import { IBadResquestErrorData, IBaseErrorData } from "../../types/fetch";
import { errorMessage, successMessage } from "../../toastify/notification";
import { AiOutlineLoading3Quarters, AiOutlineUndo } from "react-icons/ai";
import { getcountry } from "../../utils/country";
import { ICountry } from "../../types/country";

type Props = {};

const Form = () => {
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [tel, setTel] = useState<string>("");
  const [errorFirstname, setErrorFirstname] = useState<string>("");
  const [errorLastname, setErrorLastname] = useState<string>("");
  const [errorEmail, setErrorEmail] = useState<string>("");
  const [errorTel, setErrorTel] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [country, setCountry] = useState<ICountry[]>([]);
  const [selected, setSelected] = useState<ICountry | null>(null);
  const [onOpen, setOnOpen] = useState<boolean>(false);

  useEffect(() => {
    getcountry().then((el) => {
      if (el === "failed") {
        console.log("error occurred!");
      } else {
        setCountry(el);
        setSelected(el[0]);
      }
    });
  }, []);

  const createNewUser = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorEmail("");
    setErrorFirstname("");
    setErrorTel("");
    setErrorLastname("");

    await createUser({
      email,
      firstName: firstname,
      lastName: lastname,
      phoneNumber: `(${selected?.code}) ${tel}`,
      country: selected?.name as string,
    })
      .then((el) => {
        if (el.statusCode === 201) {
          setErrorEmail("");
          setErrorFirstname("");
          setErrorTel("");
          setErrorLastname("");
          setEmail("");
          setFirstname("");
          setLastname("");
          setTel("");
          successMessage("You're registration was achieved!");
        } else if (el.statusCode === 400) {
          const obj = el as IBadResquestErrorData;
          obj.data.map((x) => {
            if (x.message.split("is")[0].includes("email")) {
              setErrorEmail(x.message);
            } else if (x.message.split("is")[0].includes("firstName")) {
              setErrorFirstname(x.message);
            } else if (x.message.split("is")[0].includes("lastName")) {
              setErrorLastname(x.message);
            } else if (x.message.split("is")[0].includes("phoneNumber")) {
              setErrorTel(x.message);
            }
          });
        } else {
          const obj = el as IBaseErrorData;
          errorMessage(obj.message);
        }
      })
      .catch((e) =>
        errorMessage(
          "An unknown error please try later or contact the support team!"
        )
      )
      .finally(() => setIsLoading(false));
  };

  return (
    <form onSubmit={createNewUser} className="banner__box--right">
      <h2>Sign up today</h2>
      <span>Embark on your AI trading adventure in just 30 seconds</span>
      <div className="banner__box--right--inputs">
        <div className="banner__box--right--input">
          <input
            type={"text"}
            placeholder="First Name"
            value={firstname}
            onChange={(event) => setFirstname(event.target.value)}
          />
          {errorFirstname !== "" && <span>{errorFirstname}</span>}
        </div>
        <div className="banner__box--right--input">
          <input
            type={"text"}
            placeholder="Last Name"
            value={lastname}
            onChange={(event) => setLastname(event.target.value)}
          />
          {errorLastname !== "" && <span>{errorLastname}</span>}
        </div>
        <div className="banner__box--right--input">
          <input
            type={"email"}
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          {errorEmail !== "" && <span>{errorEmail}</span>}
        </div>
        <div className="banner__box--right--input">
          <div className="banner__box--right--inputs__tel">
            <div
              className="banner__box--right--inputs__tel--index"
              onClick={() => setOnOpen(!onOpen)}
            >
              <img src={selected?.flag} alt="" /> <span>{selected?.code}</span>
              <div
                className={
                  onOpen
                    ? `banner__box--right--inputs__tel--index__options open`
                    : "banner__box--right--inputs__tel--index__options"
                }
              >
                {country?.map((el) => (
                  <div
                    onClick={() => {
                      setSelected(el);
                      setOnOpen(false);
                    }}
                  >
                    <img src={el?.flag} alt="" /> <span>{el?.code}</span>
                  </div>
                ))}
              </div>
            </div>
            <input
              type={"tel"}
              placeholder="Phone"
              value={tel}
              onChange={(event) => setTel(event.target.value)}
            />
          </div>
          {errorTel !== "" && <span>{errorTel}</span>}
        </div>
      </div>
      <button type={"submit"}>Create your free account</button>
      {isLoading && (
        <div className="banner__box--right--inputs__loader">
          <AiOutlineLoading3Quarters />
        </div>
      )}
    </form>
  );
};

export default Form;
