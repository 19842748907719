import React from "react";

export interface ICard {
  id: number;
  title: string;
  text: string;
  step: string;
}

const CardData: ICard[] = [
  {
    id: 1,
    step: "step one",
    title: "Registration",
    text: "Begin by completing the registration form available on our website. You will need to provide essential information, such as your first name, last name, email address, and phone number. Once you receive a confirmation email, click on the provided link to activate your account. After activation, you will have full access to the Quantum Trade Pro trading application.",
  },
  {
    id: 2,
    step: "step two",
    title: "Deposit Funds",
    text: "After activating your account, you will need to deposit funds. Initial capital is required to execute trades in the financial markets. Quantum Trade Pro requires a minimum deposit to start trading. While a larger investment increases your potential profit, it also involves higher risk. For beginners, it is recommended to start with the minimum deposit to familiarize yourself with the basics of trading before increasing your investment.",
  },
  {
    id: 3,
    step: "step three",
    text: "inally, configure the trading parameters to suit your needs. The software allows you to adjust settings based on your risk tolerance, preferences, and financial goals. This customization ensures an optimal trading experience. Once your parameters are set, you will be ready to execute trades using our advanced algorithm and market analysis tools.",
    title: "Set Up and Trade",
  },
];

type Props = {};

const Price = (props: Props) => {
  return (
    <div className="price__container" id="pricing">
      <div className="price__wrapper">
        <h2>How do you sign up?</h2>
        <div className="price__cards">
          {CardData.map((el) => (
            <div key={el.id} className="price__card">
              <span>{el.step}</span>
              <h2>{el.title}</h2>
              <p>{el.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Price;
