import React from "react";

type Props = {};

const Footer = (props: Props) => {
  return (
    <div className="footer__container">
      <div className="footer__wrapper">
        <div className="footer__content">
          <div className="footer__company">
            <span>TOP-INVESTMENT</span>
            <p>
              We empower individuals with cutting-edge trading tools to navigate
              the financial markets confidently. Our platform combines
              user-friendly design with advanced algorithms for real-time market
              insights. Secure, flexible, and customizable, ApexTrader supports
              traders of all levels in achieving their financial goals.
            </p>
          </div>
          <div className="footer__right">
            <div className="footer__links">
              <span>Links on site</span>
              <a href="/#about">ABOUT</a>
              <a href="/#reviews">REVIEWS</a>
              <a href="/#pricing">PRICING</a>
            </div>
            <div className="footer__legal">
              <span>Legal</span>
              <a href="">Privacy Policy</a>
              <a href="">Terms of Use</a>
            </div>
            <div className="footer__legal">
              <span>Contact</span>
              <a href="">The Mall, London SW1Y 5AS, Royaume-Uni</a>
              <a href="">info@apexT.com</a>
              <a href="">+44 2079316847</a>
            </div>
          </div>
        </div>
        <div className="footer__copyright">
          <p>Copyright © 2024</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
