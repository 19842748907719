import React from "react";
import { reviewsData } from "../../data/reviews";
import ReviewCard from "./ReviewCard";

type Props = {};

const Review = (props: Props) => {
  return (
    <div className="review__container" id="reviews">
      <div className="review__wrapper">
        <div className="review__heading">
          <h2>testimonials</h2>
          <p>
            Don't Just Take Our Word For It - Hear from Our Thrilled Customers
          </p>
        </div>
        <div className="review__cards">
          <div className="review__cards--wrapper">
            {reviewsData.map((review) => (
              <ReviewCard key={review.id} review={review} />
            ))}
          </div>
        </div>
      </div>
      <div className="bulle" />
    </div>
  );
};

export default Review;
