import React from "react";
import { overviewData } from "../../data/overview";
import SelectOverviewIcon from "./SelectOverviewIcon";

type Props = {};

const Overview = (props: Props) => {
  return (
    <div className="overview__container">
      <div className="overview__wrapper">
        <div className="overview__heading">
          <h2>Company name Overview</h2>
          <p>
            Experience the power of advanced AI-driven trading, effortless
            deposits, compatibility across multiple devices, and worldwide
            accessibility. Unlock your full potential starting today.
          </p>
        </div>
        <div className="overview__cards">
          {overviewData.map((overview) => (
            <div className="overview__card">
              <div className="overview__card--left">
                <SelectOverviewIcon title={overview.title} />
                <h3>{overview.title}</h3>
              </div>
              <p className="overview__card--right">{overview.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Overview;
