import React, { useEffect } from "react";
import Navbar from "./components/navigation/Navbar";
import Banner from "./components/heading/Banner";
import Info from "./components/info/Info";
import Review from "./components/review/Review";
import Faq from "./components/faq/Faq";
import Overview from "./components/overview/Overview";
import Footer from "./components/footer/Footer";
import Price from "./components/price/Price";
import { getcountry } from "./utils/country";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Banner />
      <Info />
      <Review />
      <Overview />
      <Price />
      <Faq />
      <Footer />
    </div>
  );
}

export default App;
