import React from "react";
import { CiMenuFries } from "react-icons/ci";

type Props = {};

//yes

const Navbar = (props: Props) => {
  return (
    <header className="header__container">
      <nav className="navbar__container">
        <div className="navbar__logo">TOP-INVESTMENT</div>
        <ul className="navbar__navigation">
          <li className="navbar__link">
            <a href="">ABOUT</a>
          </li>
          <li className="navbar__link">
            <a href="">REVIEWS</a>
          </li>
          <li className="navbar__link">
            <a href="">PRICING</a>
          </li>
        </ul>
        <button>
          <CiMenuFries />
        </button>
      </nav>
    </header>
  );
};

export default Navbar;
