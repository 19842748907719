import React from "react";
import { IReviewsData } from "../../types/user";

type Props = {
  review: IReviewsData;
};

const ReviewCard = ({ review }: Props) => {
  return (
    <div className="review__card">
      <div className="review__card--heading">
        <div className="review__card--heading__picture">
          <img
            alt={review.username}
            src={`https://avatar.iran.liara.run/public/${review.gender}?username=${review.username}`}
          />
        </div>
        <div className="review__card--heading__content">
          <h3>{review.username}</h3>
          <span>
            <strong>country : </strong> {review.country}
          </span>
        </div>
      </div>
      <p className="review__card--description">{review.description}</p>
    </div>
  );
};

export default ReviewCard;
