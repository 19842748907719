import { IOverviewData } from "../types/user";

export const overviewData: IOverviewData[] = [
  {
    id: 1,
    title: "Deposit Options",
    description: "Credit/Debit Card, Bank Transfer, Neteller, Skrill, & PayPal",
    icon: "<BsCreditCard2BackFill />",
  },
  {
    id: 2,
    title: "Platform Accessibility",
    description: "Accessible via Browser",
    icon: "<BsFillLaptopFill />",
  },
  {
    id: 2,
    title: "Supported Locations",
    description:
      "Functioning in Canada, Australia, Europe (including France, Germany, Italy, Netherlands, United Kingdom, and more), the Americas (excluding the USA), and Asia.",
    icon: "<BsGlobeEuropeAfrica />",
  },
  {
    id: 4,
    title: "Price",
    description: "Free of charge",
    icon: "<BsCashCoin />",
  },
];
