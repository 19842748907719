import axios from "axios";
import { ICountry } from "../types/country";

export const getcountry = async () => {
  try {
    const canada = await axios.get(
      `https://restcountries.com/v3.1/name/canada`
    );
    const suisse = await axios.get(
      `https://restcountries.com/v3.1/name/suisse`
    );
    const france = await axios.get(
      `https://restcountries.com/v3.1/name/france`
    );
    const belgique = await axios.get(
      `https://restcountries.com/v3.1/name/belgique`
    );

    const canadaData: ICountry = {
      code: "+1",
      flag: canada.data[0]?.flags?.png as string,
      name: canada.data[0]?.name?.common,
    };

    const franceData: ICountry = {
      code: "+33",
      flag: france.data[0]?.flags?.png as string,
      name: france.data[0]?.name?.common,
    };

    const suisseData: ICountry = {
      code: "+41",
      flag: suisse.data[0]?.flags?.png as string,
      name: suisse.data[0]?.name?.common,
    };

    const belgiqueData: ICountry = {
      code: "+32",
      flag: belgique.data[0]?.flags?.png as string,
      name: belgique.data[0]?.name?.common,
    };

    const countryData: Array<ICountry> = [
      franceData,
      suisseData,
      belgiqueData,
      canadaData,
    ];

    return countryData;
    // console.log(countryData);
  } catch (error) {
    return "failed";
  }
};
