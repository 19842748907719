import React from "react";
import { faqData } from "../../data/faq";

type Props = {};

const Faq = (props: Props) => {
  return (
    <div className="faq__container">
      <div className="faq__wrapper">
        <div className="faq__heading">
          <h2>FREQUENTLY ASKED QUESTIONS</h2>
          <span>Looking for more information? We've got you covered.</span>
          <p>
            Can’t find the answer you’re looking for? Don’t worry, our support
            team is here to help. Feel free to <a>reach out via email</a> and
            we’ll make it a priority to get back to you as soon as we can.
          </p>
        </div>
        <div className="faq__contents">
          {faqData.map((content) => (
            <div key={content.id} className="faq__card">
              <h3>{content.title}</h3>
              <p>{content.comment}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
