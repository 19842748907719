import React from "react";
import { IDataCard } from "./Info";

type Props = {
  content: IDataCard;
};

const InfoCard = ({ content }: Props) => {
  return (
    <div className="info__card">
      <h2>{content.title}</h2>
      <p>{content.text}</p>
    </div>
  );
};

export default InfoCard;
