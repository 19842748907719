import React from "react";
import Form from "./Form";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

type Props = {};

const Banner = (props: Props) => {
  return (
    <div className="banner__container" id="about">
      <div className="banner__wrapper">
        <div className="banner__box">
          <div className="banner__box--left">
            <h1>Get in the New Era of AI-Powered Trading 2024</h1>
            <p>
              Want to conquer the market? Welcome to our startup. With our
              state-of-the-art AI trading software, you’re empowered to make
              clever, insightful decisions that could drive exceptional returns.
            </p>
            <div className="banner__box--left__raiting">
              <div className="banner__box--left__stars">
                {[1, 2, 3, 4, 5].map((el) => (
                  <AiOutlineStar key={el} />
                ))}
                <div className="banner__box--left__stars--colored">
                  {[1, 2, 3, 4, 5].map((el) => (
                    <AiFillStar key={el} />
                  ))}
                </div>
              </div>
              <hr />
              <span>
                {" "}
                Rated 4.8 stars by over <strong>2,970 users</strong>{" "}
              </span>
            </div>
          </div>
          <Form />
        </div>
      </div>
    </div>
  );
};

export default Banner;
