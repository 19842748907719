import { instance } from "./instance";
import { IUserData } from "../types/user";
import {
  IBadResquestErrorData,
  IBaseErrorData,
  ISuccessData,
} from "../types/fetch";
import { errorToSendBack, successResponse } from "./errprHandler";

export const createUser = async (
  userData: IUserData
): Promise<ISuccessData | IBadResquestErrorData | IBaseErrorData> => {
  try {
    const { data, status } = await instance.post("user/create-user", userData);
    return successResponse(data, status);
  } catch (error) {
    return errorToSendBack(error);
  }
};
