import React from "react";
import InfoCard from "./InfoCard";

export interface IDataCard {
  id: number;
  title: string;
  text: string;
}

const dataCards: IDataCard[] = [
  {
    id: 1,
    title: "13 millions",
    text: "Transactions every 24 hours",
  },
  {
    id: 2,
    title: "14 millions",
    text: "Assets traded",
  },
  {
    id: 3,
    title: "4500",
    text: "New users annually",
  },
];

type Props = {};

const Info = (props: Props) => {
  return (
    <div className="info__container">
      <div className="info__wrapper">
        {dataCards.map((el) => (
          <InfoCard key={el.id} content={el} />
        ))}
      </div>
    </div>
  );
};

export default Info;
