import { IFaqData } from "../types/user";

export const faqData: IFaqData[] = [
  {
    id: 1,
    title: "What is TOP-INVESTMENT?",
    comment:
      "Company name is a sophisticated software application that leverages quantum computing and artificial intelligence to examine market patterns and make more informed automated trading decisions.",
  },
  {
    id: 2,
    title: "How does TOP-INVESTMENT work?",
    comment:
      "Company name deploys cutting-edge algorithms to examine market trends, current news, and social media sentiment in order to forecast future market price fluctuations. It then carries out trades based on these projections.",
  },
  {
    id: 3,
    title: "Can I rely on TOP-INVESTMENT?",
    comment:
      "Company name has undergone extensive trials and has demonstrated steady profits in both favorable and unfavorable market conditions. Nevertheless, like any trading tool, there are inherent risks and users should proceed with discretion.",
  },
  {
    id: 4,
    title: "Which markets does TOP-INVESTMENT support?",
    comment:
      "Company name caters to a broad array of markets, The advanced algorithm can execute automatic trading across the entire market using market APIs. For more details, our customer service team is always ready to assist.",
  },
  {
    id: 5,
    title: "What is the cost of TOP-INVESTMENT?",
    comment:
      "Company name application is entirely free to use, and there are no charges for creating an account.",
  },
  {
    id: 6,
    title: "How can I start using TOP-INVESTMENT?",
    comment:
      "To initiate trading with the company name platform, you just need to register for an account. After successful registration, you can immediately start trading. We recommend starting with a modest capital and gradually augmenting your investment as you gain more familiarity with the tool.",
  },
];
