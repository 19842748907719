import React from "react";
import {
  BsCashCoin,
  BsCreditCard2BackFill,
  BsFillLaptopFill,
  BsGlobeEuropeAfrica,
} from "react-icons/bs";

type Props = {
  title: string;
};

const SelectOverviewIcon = ({ title }: Props) => {
  switch (title) {
    case "Deposit Options":
      return <BsCreditCard2BackFill />;
      break;
    case "Platform Accessibility":
      return <BsFillLaptopFill />;
      break;
    case "Supported Locations":
      return <BsGlobeEuropeAfrica />;
      break;
    case "Price":
      return <BsCashCoin />;
      break;
    default:
      return <></>;
      break;
  }
};

export default SelectOverviewIcon;
