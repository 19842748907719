import { IReviewsData } from "../types/user";

export const reviewsData: IReviewsData[] = [
  {
    id: 1,
    username: "Max",
    description:
      "I've experimented with numerous trading bots in the past, but none can hold a candle to startup name. Its sophisticated algorithms and real-time analysis have guided me towards profitable trades.",
    gender: "boy",
    country: "Canada",
  },
  {
    id: 2,
    username: "Lidia",
    description:
      "Company name is revolutionizing the field! The trading bot has streamlined the trading journey and has been instrumental in delivering steady returns for me.",
    gender: "girl",
    country: "Canada",
  },
  {
    id: 3,
    username: "Alexandra",
    description:
      "Initially, I was dubious about the bold claims made by company name, but after making my first deposit and using it for a few months, I'm astounded by the precision of its predictions and signals.",
    gender: "girl",
    country: "France",
  },
  {
    id: 4,
    username: "Benjanin",
    description:
      "As a novice in the realm of trading, company name has been an invaluable learning resource. It has enlightened me about market trends and steered me towards profitable trades.",
    gender: "boy",
    country: "France",
  },
  {
    id: 5,
    username: "Françoise",
    description:
      "I've been relying on company name bots for over a year now for live trading, and it has consistently aided me in reaping high returns. It's an indispensable trading system for any committed trader.",
    gender: "girl",
    country: "belgique",
  },
  {
    id: 6,
    username: "Lolita",
    description:
      "company name has freed up a considerable amount of my time that was previously spent in scrutinizing market data. Its intuitive interface and automated trading capabilities have significantly simplified my life.",
    gender: "girl",
    country: "France",
  },
];
